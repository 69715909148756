import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { AcColumn, AcFormProvider, AcOrderFooter } from '@components'
import { useStore } from '@hooks'
import {
  AcOrdersummaryExtrasForminputs,
  AcOrdersummaryExtrasPricingDaily,
  AcOrdersummaryExtrasPricingSum,
} from '@partials'

export interface IAcOrderSummaryForm {
  isEditOrder?: boolean
}

export const AcOrderSummaryForm = observer(
  ({ isEditOrder }: IAcOrderSummaryForm) => {
    const navigate = useNavigate()
    const { weekIdentifier, orderId } = useParams()
    const { currentOrder, products, toaster } = useStore()
    const [errors, setErrors] = useState()

    const onSubmit = async (body: any) => {
      try {
        // Edit order
        if (orderId && currentOrder?.currentOrderId === parseInt(orderId)) {
          const updatedOrder = await currentOrder.editOrder(
            currentOrder.currentOrderId,
            body,
            products.extras,
          )
          if (updatedOrder && weekIdentifier) {
            currentOrder.reset()
            navigate(NAVIGATE_TO.EDIT_ORDER_COMPLETED(weekIdentifier))
          }
        } else {
          // Create new order
          const order = await currentOrder.createOrder(body, products.extras)
          if (order && weekIdentifier) {
            currentOrder.reset()
            navigate(NAVIGATE_TO.ORDER_COMPLETED(weekIdentifier))
          }
        }
      } catch (error: any) {
        if (error.type === 'axios') {
          setErrors(error.errorFields)
        }
        toaster.error({
          title: 'Nog niet alle velden zijn juist ingevuld',
          content: error.message,
        })
      }
    }

    if (!weekIdentifier) return null

    return (
      <AcFormProvider
        initial={currentOrder.formValues}
        onSubmit={onSubmit}
        errors={errors}>
        <AcColumn>
          {products.current_extras && (
            <>
              <AcOrdersummaryExtrasForminputs />
              <AcOrdersummaryExtrasPricingDaily />
              <AcOrdersummaryExtrasPricingSum />
            </>
          )}
          <AcOrderFooter
            islastStep
            loading={currentOrder.watchLoadingSubmitting}
            isOrderEdit={isEditOrder}
            previousStep={
              orderId
                ? NAVIGATE_TO.EDIT_ORDER_PREFERENCES(weekIdentifier, orderId)
                : NAVIGATE_TO.ORDER_PREFERENCES(weekIdentifier)
            }
          />
        </AcColumn>
      </AcFormProvider>
    )
  },
)
