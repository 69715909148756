import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { DIALOGS, LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'
import { ILegendItem } from '@typings'

import { AcButton } from '../../core/ac-button/ac-button'
import { AcCard } from '../../core/ac-card/ac-card'
import { AcColumn } from '../../core/ac-column/ac-column'
import { AcIconButton } from '../../core/ac-icon-button/ac-icon-button'
import { AcRow } from '../../core/ac-row/ac-row'
import { AcTypography } from '../../core/ac-typography/ac-typography'
import { AcProgress } from '../ac-progress/ac-progress'

interface IAcOrderHeaderProps {
  weekIdentifier: string
  step: number
  isEditOrder?: boolean
  orderId?: string
}

export const AcOrderHeader = observer(
  ({
    weekIdentifier,
    step,
    isEditOrder = false,
    orderId,
  }: IAcOrderHeaderProps) => {
    const { dialog, weeks, toaster, user, currentOrder, products } = useStore()
    const navigate = useNavigate()
    const { isMobile } = useBreakpoints()
    const [week] = weekIdentifier.split('-')
    const [isDownloading, setIsDownloading] = useState(false)

    const downloadList = async () => {
      setIsDownloading(true)
      try {
        await weeks.downloadList(weekIdentifier)
        toaster.success({ content: 'De luncherlijst is gedownload' })
      } catch (error: any) {
        toaster.error({
          title: 'Let op',
          content:
            error?.message ||
            'Er ging iets mis, probeer het later nog een keer',
        })
      } finally {
        setIsDownloading(false)
      }
    }

    const flexDirection = useMemo(() => {
      return {
        default: isMobile ? 'column' : 'row',
        inverse: isMobile ? 'row' : 'column',
      }
    }, [isMobile])

    const renderPreferencesButton = useMemo(() => {
      if (!user.hasApp) return null
      if (isMobile) {
        return (
          <AcIconButton
            onClick={() => dialog.open(DIALOGS.LUNCH_PREFERENCES)}
            sx={{ padding: 0 }}
            color="green">
            <TroubleshootOutlinedIcon />
          </AcIconButton>
        )
      }

      return (
        <AcButton
          onClick={() => dialog.open(DIALOGS.LUNCH_PREFERENCES)}
          startIcon={<TroubleshootOutlinedIcon />}
          variant="text">
          {LABELS.LUNCH_PREFERENCES}
        </AcButton>
      )
    }, [isMobile])

    const renderDownloadButton = useMemo(() => {
      if (!user?.hasApp) return null
      if (!weeks?.current?.download) return null
      if (isMobile) {
        return (
          <AcIconButton
            onClick={downloadList}
            sx={{ padding: 0 }}
            color="green">
            <FileDownloadOutlinedIcon />
          </AcIconButton>
        )
      }
      return (
        <AcButton
          onClick={downloadList}
          startIcon={<FileDownloadOutlinedIcon />}
          variant="text"
          loading={isDownloading}>
          {LABELS.DOWNLOAD_LUNCHER_LIST}
        </AcButton>
      )
    }, [isMobile, weeks.current?.download, isDownloading])

    const renderTitle = useMemo(() => {
      return (
        <AcColumn
          gap={2}
          sx={{
            flexDirection: flexDirection.inverse,
            justifyContent: isMobile ? 'space-between' : 'initial',
          }}>
          <AcRow>
            <AcTypography
              removePadding
              component="h2"
              color="green.main">
              Bestelling week {week} {isEditOrder ? LABELS.EDIT : ''}
            </AcTypography>
          </AcRow>
          <AcRow gap={isMobile ? 3 : 2}>
            {renderPreferencesButton}
            {renderDownloadButton}
          </AcRow>
        </AcColumn>
      )
    }, [isMobile, weeks.current])

    const renderStats = useMemo(() => {
      if (!user?.hasApp) return null
      if (!weeks) return null
      return (
        <AcCard sx={{ padding: 3 }}>
          <AcRow gap={5}>
            {weeks.legend?.map((item: ILegendItem) => (
              <AcColumn gap={0}>
                <AcTypography
                  color="green.main"
                  removePadding
                  bold>
                  {item.value}
                </AcTypography>
                <AcTypography
                  color="green.main"
                  removePadding>
                  {item.label}
                </AcTypography>
              </AcColumn>
            ))}
          </AcRow>
        </AcCard>
      )
    }, [weeks.current])

    useEffect(() => {
      weeks.getWeek(weekIdentifier)
      weeks.getWeekLunchers(weekIdentifier)
      if (orderId) {
        if (parseInt(orderId) !== currentOrder?.currentOrderId) {
          currentOrder.reset()
          currentOrder.fetchOrderById(parseInt(orderId), true)
        }
      }
    }, [orderId, currentOrder?.currentOrderId])

    useEffect(() => {
      switch (weeks?.current?.status) {
        case 'confirmed':
          toaster.warning({
            title: 'Let op',
            content: `De bestelling voor week ${weekIdentifier} is al bevestigd.`,
          })
          navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
          break

        case 'cancelled':
          toaster.error({
            title: 'Let op',
            content: `De bestelling voor week ${weekIdentifier} is geannuleerd.`,
          })
          navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
          break

        default:
          break
      }
    }, [weeks.current?.status])

    return (
      <AcColumn gap={5}>
        <AcRow
          sx={{
            flexDirection: flexDirection.default,
            justifyContent: 'space-between',
          }}>
          {renderTitle}
          {renderStats}
        </AcRow>
        {!user?.isBasicSubscription && (
          <AcProgress
            steps={3}
            current={step}
          />
        )}
      </AcColumn>
    )
  },
)
